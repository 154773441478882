function renderButton() {
    gapi.signin2.render('google-signin2', {
        'scope': 'profile email',
        'height': 56,
        'longtitle': true,
        'onSuccess': onGoogleSignIn
    });
}
function onGoogleSignIn(googleUser) {
    var profile = googleUser.getBasicProfile();
    var googleId = profile.getId();
    var email = profile.getEmail();
    var firstname = profile.getGivenName();
    var lastname = profile.getFamilyName();
    if(googleId && email) {
        jQuery.ajax({
            url: "externallogin",
            type: "POST",
            data: { email: email, externalId: googleId, firstname: firstname, lastname: lastname, login_type: 'google' },
            statusCode: {
                400: function(error) {
                    console.log(error.responseText);
                    googleSignOut();
                },
                200: function(response) {
                    googleSignOut();
                    window.location.replace("//" + response);
                }

            }
        });
    } else {
        googleSignOut();
    }
}
function googleSignOut() {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {});
}

function checkLoginState() {
    FB.login(function (response) {
        if(response.status == "connected") {
            onFacebookSignIn(response);
        }
    }, {scope: 'email'});
}
function onFacebookSignIn() {
    FB.api('/me', { locale: 'en_US', fields: 'id, first_name, last_name, email' }, function(response) {
        var facebookId = response.id;
        var email = response.email;
        var firstname = response.first_name;
        var lastname = response.last_name;
        if(facebookId && email) {
            jQuery.ajax({
                url: "externallogin",
                type: "POST",
                data: { email: email, externalId: facebookId, firstname: firstname, lastname: lastname, login_type: 'facebook' },
                statusCode: {
                    404: function() {
                        facebookLogOut();

                    },
                    400: function() {
                        facebookLogOut();
                    },
                    200: function(response) {
                        facebookLogOut();
                        window.location.replace("//" + response);
                    }

                }
            });
        } else {
            facebookLogOut();
        }
    });
}
function facebookLogOut() {
    FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
            FB.logout(function(response) {});
        }
    });
}